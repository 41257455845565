import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DashBoardLoanInfo } from 'utils/assets'
import Colors from 'utils/colors'
import media from 'utils/media'

export const LoanContainer = styled.div`
  padding: 30px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.mobile`
 padding: 0px 0px ;
  `}
`
export const FirstDiv = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
  padding: 30px 60px;

  ${media.mobile`
	display: flex;
  flex-direction: column;
  padding: 0px 30px;
  /* background: red; */
  margin-top: -15%;
	`}
`

export const LoanLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  /* background: ${Colors.gradient}; */

  ${media.smallDesktopMinimum`
  height: 500px;
 
  padding: 10px;
  
  `}

  ${media.mobile`
  width:100%;
	`}
`
export const LeftText = styled.div`

  ${media.mobile`
  width: 100%;
  
  `}
`

export const LoanTextHead = styled.h3`
  width: 95%;
  font-weight: 600;
  font-size: 34px;
  color: ${Colors.primary};
  
  ${media.smallDesktopMinimum`
  
  font-size: 24px;
  `}
  
  ${media.mobile`
  
  width: 100%;
  font-size: 20px;
  font-weight: 550;
  `}

  ${media.smallMobile`
  background: red;
  `}
`
export const LoanTextPara = styled.p`
  width: 95%;
  font-size: 16px;
  padding-top: 10px;
  color: black;
  word-spacing: 3px;
  line-height: 25px;

  span {
    color: ${Colors.primary};
    font-weight: 600;
  }

  ${media.mobile`
  
  width: 100%;
font-size: 14px;
word-spacing: 1px;
  line-height: 20px;
  
  `}
`
export const LoanBlogBtn = styled(Link)`
  width: 150px;
  /* height: 50px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: 'Montserrat', sans-serif;
  color: #ed3237;
  text-decoration: none;
  font-weight: 550;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 10px;
  /* background: ${Colors.gradient}; */
  cursor: pointer;

  ${media.mobile`
  /* margin-top: 20px; */

`}
`
export const LoanRight = styled.div`
  width: 50%;
  padding: 20px 30px;

  img {
    width: 100%;
    height: 100%;
     border-radius: 10px;
  }

  ${media.smallDesktopMinimum`
 height: 500px;
  `}

  ${media.mobile`
 width:100%;
 height: 50%;
 margin-top: -40px;
 margin-bottom: 40px;
 padding: 0px 0px;

  `}
`
export const SecondDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
    margin-top:7%;

  ${media.mobile`
    margin-top:0%;
`}
`
export const LoanInfoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: Helvetica Rounded;
font-weight: 700;
font-size: 36px;
line-height: 47.2px;
letter-spacing: 0%;
 padding: 20px;
`;

export const ImageContentWrapper = styled.div`
	width: 100%;
	height: auto;
	background-image: url(${DashBoardLoanInfo});

`
export const LoanInfoBackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto; 
  object-fit: cover;
  z-index: -1; 

${media.mobile`
  position: absolute;
  width: 100%;
  top:10px;
  height: 767px; 
  object-fit: cover;
  z-index: -1; 
`}





`;
export const LoanTextWrapper = styled.div`
align-items:center;
margin-left:30%;
padding:20px;
${media.mobile`
align-items:center;
margin-left:2%;
  `}
`
export const ClippedContainer = styled.div`
  position: relative;
  display: flex;
  text-align: left;
  margin-top:15px;
`;

export const ClippedText = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: white;
  width:100%;
${media.mobile`
  font-size: 32px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  width:100%;
`}
`;

export const ClippedShape = styled.img`
  width: 100px;
  height: 10px;
  object-fit: cover;
   position: absolute;
  bottom: -7px;
  left:300px;
  ${media.mobile`
  display:none
`}
  ${media.smallDesktopMinimum`
  display:none
`}
`;
export const LoanItemDiv = styled.div`
  display: flex;
   align-items: center;
   gap:5px;
   margin-top:12px;
   margin-bottom:12px;
width:100%;
align-contents:center;
color:white;

   h2{
   font-family: Inter;
font-weight: 600;
font-size: 16px;


 ${media.mobile`
  font-weight: 600;
font-size: 13px;
  `}
   }
    img {
    width: 13px;
    height: 13px;
  }
  



`
export const LoanFooterButton = styled.div`
  width: 314px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid white;
  padding: 10px;
  color: white;
  font-size: 16px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
cursor:pointer;

${media.mobile`
  margin-top:40px
  `}
`
export const LoanFootComp = styled.div`
  width: 92%;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  background: ${Colors.gradient};

  ${media.smallDesktopMinimum`
  height: 100%;


  `}
  ${media.mobile`
  padding: 20px 0px;

  `}
`
export const HeroBtn = styled.button`
  width: 100%;
  padding: 15px 25px;
  margin-top: 20px;
  background: ${Colors.white};
  border-radius: 5px;
  color: ${Colors.primaryLight};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.025em;
  cursor: pointer;

  ${media.mobile`
  padding: 10px 20px;
  font-size: 12px;

  
  `}
`

export const LoanFootHead = styled.h3`
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;

  ${media.mobile`
	font-size: 14px;
	padding-top:10px ;
	
	`}
`
