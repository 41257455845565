import * as Yup from 'yup'
import { useEffect, useState } from "react"
import { getCurrentLoanStage } from "services/LoanService"
import { uniData } from 'components/Dashboard/Apply/uniData'
import "components/CheckBox/radio.css"

 const validationSchema = Yup.object({
        name: Yup.string().max(350).required().label('Institution Name'),
        course: Yup.string().max(50).required().label('Course'),
        faculty: Yup.string().max(50).required().label('Faculty'),
        matric_number: Yup.string().max(150).label('Matric Number'),
        study_year: Yup.string().max(100).required().label('Study Year'),
        graduation_year: Yup.string().max(255).required().label('Admission Year'),
        othercertificate: Yup.string().when('certificate', {
          is: 'Others',
          then: Yup.string().required().label('Other Cert'),
        }),
        othername: Yup.string().when('name', {
          is: 'Others',
          then: Yup.string().required().label('Enter Institution'),
        }),
      })


export function useInstitutionApplication() {
    const [formValues, setFormValues] = useState({
        name: '',
        course: '',
        faculty: '',
        matric_number: '',
        study_year: '',
        graduation_year: '',
        certificate: '',
        othercertificate: '',
        othername: '',
    });

    const handleInput = (e) => {
        setFormValues(prev => ({
            ...prev,
            [e.target.name]: e.target.value.trim(),
        }));
    };

    const resetForm = () => {
        setFormValues({
            name: '',
            course: '',
            faculty: '',
            matric_number: '',
            study_year: '',
            graduation_year: '',
            certificate: '',
            othercertificate: '',
            othername: '',
        });
    };

    return {
        handleInput,
        formValues,
        resetForm, 
    };
}

export default function useInstitutionForm(segment){
    const [loadingForm,setLoadingForm] = useState(false)
    const [loanData,setLoanData] = useState(null)
	const [formValues,setFormValues] = useState({
        name: ' ',
        course: ' ',
        faculty: ' ',
        matric_number: '',
        study_year: ' ',
        graduation_year: '',
        certificate: '',
        othercertificate: '',
        othername: '',
	})

   
    const handleInput = (e)=>{
        setFormValues(prev=>({
            ...prev,
            [e.target.name]:e.target.value
        }))
    }

    useEffect(()=>{
        console.log(formValues)
    },[formValues])

    useEffect(()=>{
        const getUserData = async()=>{
            setLoadingForm(true)
            try {
                const res = await getCurrentLoanStage({ search: "institution", loanId: segment });
                const {institution} = res?.data?.data;
                setLoanData(res?.data?.data)

                const {
                    course,
                    current_study_year,
                    faculty,
                    graduation_year,
                    matric_number,
                    certificate,
                    othercertificate,
                    name,
                }  = institution

                const uniDataNames = uniData.map(uni => uni.value)
                console.log(institution)
                setFormValues({
                    name:uniDataNames.includes(name)?name:"others",
                    course,
                    faculty,
                    matric_number,
                    study_year:current_study_year,
                    graduation_year,
                    certificate,
                    othercertificate,
                    othername: (!uniDataNames.includes(name)) ? name :"",
                })
            } catch (error) {
                console.log(error)
            }finally{
                setLoadingForm(false)
            }

		}
		getUserData()
    },[segment])

    useEffect(()=>{
     
    },[formValues])

   return{
    handleInput,
    loanData,
    loadingForm,
    formValues,
    validationSchema
   }
}