import styled from "styled-components"
import { FooterLines } from "utils/assets"
import Colors from "utils/colors"
import media from "utils/media"

export const FooterContainer = styled.div`
	width: 100%;
	height: 350px;
	background: ${Colors.purple};
	${media.mobile`
  height: 100%;
  
  `}
`
export const FooterContentContainer = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${FooterLines});
`

export const FooterDiv = styled.div`
	display: flex;
	width: 100%;
	padding: 20px 60px;
	align-items: center;

	${media.mobile`
  flex-direction: column-reverse;
  margin-top: 40px;
  width: 100%;
  padding: 40px 10px;

  `}
`
export const FooterLeft = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	.copyright-text {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.025em;
		text-transform: capitalize;
		color: ${Colors.white};
	}
	.desc-text {
		max-width: 70%;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${Colors.white};
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	${media.mobile`
  width:100%;
.desc-text {
    max-width: 100%;
    font-size: 12px;
    line-height: 22px;
  }

  `}
`
export const FooterRight = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	padding:20px;
	border-radius:10px;
	background-color:${Colors.darkPurple};

	${media.mobile`
  width: 100%;
  height: 100%;
  margin-bottom:30px;
  
  `}
`

export const FooterUl = styled.ul`
	list-style-type: none;
	display: flex;
	margin-bottom: 20px;
	/* background-color: red; */
	.links-footer {
		margin-right: 20px;
		/* margin-top: -10px; */
		/* margin-bottom: 5px; */
		color: ${Colors.white};
		text-decoration: none;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
	}
	.bottom-link-start {
		color: ${Colors.white};
		margin-right: 20px;
	}
	${media.mobile`
  display:none;
  
  `}
`

export const SocialDivFooter = styled.div`
	display: flex;
	align-items: center;
	gap:30px;

	${media.mobile`
  /* flex-direction: column; */
  `}
`

export const FooterItemDiv = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap:9px;
	.text-contact {
		letter-spacing: 0.0075em;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 25px;

		color: ${Colors.white};
	}
`