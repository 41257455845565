import Notification from "components/Dashboard/Home/Notifications";
import AuthLayout from "layout/AuthLayout";

export default function NotificationPage() {
    return (
       <AuthLayout>
         <div className="p-4 max-w-[1000px] mx-auto">
            <Notification isPage/>
        </div>
       </AuthLayout>
    )
}