import MobileMenu from 'components/MobileMenu'
import { Img } from 'globalStyles'
import { observer } from 'mobx-react-lite'
import authStore from 'mobx/AuthStore'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  ABOUT_ROUTE,
  BLOG_ROUTE,
  CONTACT_ROUTE,
  DASHBOARD_ROUTE,
  FAQ_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  REQUIREMENT_ROUTE,
} from 'routes'
import { ByshelbDescLogo, HamburgerIcon } from 'utils/assets'
import Colors from 'utils/colors'
import {
  CreateAccountButton,
  HamBurgerBtn,
  NavActionButton,
  NavBarEndDiv,
  NavContainer,
  Overlay,
  Ul,
} from './style'


function Navbar() {
  const [isTop, setIsTop] = useState(false)
  const [open, setOpen] = useState(false)
  const location = useLocation()


  const toggleNav = () => {
    setOpen(!open)
  }

  const changeNavBackground = () => {
    if (window.scrollY >= 100) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  }
  window.addEventListener('scroll', changeNavBackground)
  const navigate = useNavigate()
  useEffect(() => {
    const status = authStore.loggedInStatus()
    if (status) {
      navigate(`${DASHBOARD_ROUTE}`)
    }
    window.scrollTo(0, 0)
  }, [navigate])
  return (
    <>
      {open && <MobileMenu isOpen={open} close={() => toggleNav()} />}
      <NavContainer bgColor={isTop ? Colors.white : 'white'}>
        <Link className="home-link" to={HOME_ROUTE}>
          <Img src={ByshelbDescLogo} alt="BSHELF" />
        </Link>
        <Ul className="link-list" active={true}>
          <li>
            <Link
              className="nav-link-item"
              style={{ color: location.pathname === '/' ? 'red' : '' }}
              to={HOME_ROUTE}
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              className="nav-link-item"
              style={{
                color: location.pathname.includes(ABOUT_ROUTE) ? 'red' : '',
              }}
              to={ABOUT_ROUTE}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              className="nav-link-item"
              style={{
                color: location.pathname.includes(FAQ_ROUTE) ? 'red' : '',
              }}
              to={FAQ_ROUTE}
            >
              FAQS
            </Link>
          </li>
          <li>
            <Link
              className="nav-link-item"
              style={{
                color: location.pathname.includes(CONTACT_ROUTE) ? 'red' : '',
              }}
              to={CONTACT_ROUTE}
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              className="nav-link-item"
              style={{
                color: location.pathname.includes(BLOG_ROUTE) ? 'red' : '',
              }}
              to={BLOG_ROUTE}
            >
              Blog
            </Link>
          </li>
        </Ul>
        <NavBarEndDiv>
          <Link className="nav-link-item" to={LOGIN_ROUTE}>
            <NavActionButton>Sign in</NavActionButton>
          </Link>
          <Link className="nav-link-item" to={REQUIREMENT_ROUTE}>
            <CreateAccountButton createBtnColor={isTop ? '#F3F3F3' : Colors.white}>
              Create an account

            </CreateAccountButton>
            {/* <CreateButton >
            </CreateButton> */}
          </Link>
          <HamBurgerBtn onClick={() => toggleNav()}>
		  <Overlay open={open} bgColor={Colors.primaryLight} />
            <Img src={HamburgerIcon} alt="Menu" />
          </HamBurgerBtn>
        </NavBarEndDiv>
        
      </NavContainer>
    </>
  )
}

export default observer(Navbar)
