import FullScreenLoader from "components/FullScreenLoader"
import AuthLayout from "layout/AuthLayout"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useNavigate, } from "react-router-dom"
import useSWR from "swr"
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE } from "routes"
import http from "services/httpService"
import CompleteYourProfile from "components/CompleteProfile"
import apiRoute, { onBoardStep } from "routes/apirRoutes"
import toast from "react-hot-toast"
import { checkOnBoarding } from "services/MiscService"
import InstitutionForm from "../InstitutionForm"
import TermsAndConditions from "../TermsAndConditions"


export const UndergraduateApplication = ()=>{
	const navigate = useNavigate()
	const [accepted,setAccepted] = useState(false)
	const acceptTerms = ()=>{
		setAccepted(true)
	}
	const rejectTerms = ()=>{
		navigate(-1)
	}


	useEffect( ()=>{
		const handleOnBoarded = async () => {
			const info = await checkOnBoarding();
			if(info?.error) {
				toast.error(info.error);
				return;
			}
			// reroute to onboarding page
			if(!info.onBoarded && info.nextStep === onBoardStep.profile){
				navigate(DASHBOARD_PROFILEROUTE)
				return;
			}
			if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
				navigate(DASHBOARD_NEXTOFKINROUTE)
				return;
			}
			if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
				navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
				return;
			}
		  }
		handleOnBoarded();
	},[navigate]);

	return (
		<AuthLayout>
			<>
				{accepted
					?<InstitutionForm  loanType={"undergraduate"} mutate={()=>{}} />
					:<TermsAndConditions accept={acceptTerms} reject={rejectTerms}/>
				}
			</>
		</AuthLayout>
	)
}

export default observer(UndergraduateApplication)