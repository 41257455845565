import { UploadsLoader } from "components/FullScreenLoader";
import InstitutionDetailSection from "./InstitutionDetailReview";
import useLoanDetail from "./useLoanDetail";
import AmountDetail from "./AmountDetails";
import GuarantorDetail from "./GuarantorDetail";
import { useContext } from "react";
import {UndergraduateLoanStepContext} from "context/LoanStepContext";
import { PostGradLoanStepContext } from "context/PostLoanStepContext";


export default function LoanDetailReview({loanType="undergraduate"}){

    const underGradContext = useContext(UndergraduateLoanStepContext)
    const postGraduateContext = useContext(PostGradLoanStepContext)

    const getContext=()=>{
        if(loanType === "postgraduate"){
            return postGraduateContext
        }
        return underGradContext
    }

    const {next,prev} = getContext()
    const {loan,loading} = useLoanDetail()

    if(loading || !loan){
        return <UploadsLoader/>
    }
    return (
        <section>
            <div className="text-center mb-4">
                <h4 className="text-lg sm:text-3xl font-semibold text-secondary">Loan Details Review</h4>
                <p className="font-poppins">Please review your information</p>
            </div>
            <div className="mx-auto my-4 max-w-xl bg-white rounded-lg p-4">
                <InstitutionDetailSection loanType={loanType} institutionDetail={loan.institution}/>
            </div>
            <div className="mx-auto my-4 max-w-xl bg-white rounded-lg p-4">
                <AmountDetail amountDetail={loan}/>
            </div>
            <div className="mx-auto my-4 max-w-xl bg-white rounded-lg p-4">
                <GuarantorDetail title={"Guarantor One Detail"} guarantor={loan.guarantor_one}/>
            </div>
            <div className="mx-auto my-4 max-w-xl bg-white rounded-lg p-4">
                <GuarantorDetail title={"Guarantor Two Detail"} guarantor={loan.guarantor_two}/>
            </div>

            <footer className="flex py-2 gap-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
                    <button onClick={prev} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button">
                        <span>Back</span>
                    </button>
				<button onClick={next} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button">
					<span>Proceed</span>
				</button>
        </footer>
        </section>
    )
}