import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import Colors from 'utils/colors'
import media from 'utils/media'

export const HeroSecCon = styled.div`
  margin-top: 0.2rem;

  ${media.mobile`
  margin-top: 0;

`}
`

export const GetStartedBtn = styled(Link)`
 width: 246px;
  height: 54px;
  gap: 10px;
  border-radius: 6px;
  margin-top: 30px;
  padding: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  cursor: pointer;
  background: ${(props) =>
    props.variant === 'whitebg' ? Colors.purple : Colors.purple};
  border-radius: 10px;
  color: ${(props) =>
    props.variant === 'whitebg' ? Colors.purple : Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 

  opacity: 0;
  transform: scale(0.9);
  animation: fadeInScale 0.3s ease-out forwards;

  @keyframes fadeInScale {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
      &:hover {
    transform: scale(1.05); 
  }

  ${media.mobile`
    width: 300px;
    height: 50px;
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 600;
  `}

`
export const zoomInOut = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`
//Former dashboard image
  // background: url(${StdMeeting2}),
  //   linear-gradient(
  //     75.6deg,
  //     rgba(5, 0, 5, 0.97) 4.94%,
  //     rgba(5, 0, 5, 0.97) 75.35%
  //   );

export const HeroContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F3F3F6;
  ${media.smallDesktopMinimum`
padding: 10px 0px;
`}

  ${media.mobile`
	padding: 0px 30px;
	height: 100%;
`}

${media.smallMobile`
padding: 0 5px;
margin-left: -10px;
`}
`

// export const BackgroundContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   background: url(${StdMeeting2}),
//     linear-gradient(
//       75.6deg,
//       rgba(5, 0, 5, 0.97) 4.94%,
//       rgba(5, 0, 5, 0.97) 75.35%
//     );
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   position: absolute;
//   animation: ${zoomInOut} 5s ease-in-out infinite;
//   overflow: hidden;
//   z-index: -1;
// `

export const HeroOverlay = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#F3F3F6), #F3F3F6);
  z-index: 0;
`

export const HeroDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
  padding-top:20px;
  z-index: 1;
  overflow: hidden;

  ${media.smallDesktopMinimum`
margin-top:5rem;
	`}

  ${media.mobile`
  width: 100%;
  flex-direction: column;
  padding: 0px 0px;
  `}
`
export const LeftDiv = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;

  ${media.mobile`
	width: 100%;
  margin-left: 0px;
	`}
`

export const HeadGroup = styled.div`
  display: flex;
  flex-direction: column;
    ${media.mobile`
align-items:center;
padding-top:20px
	`}
`

export const HeroBoldHeader = styled.h2`
  width: 95%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height:52px;
  color: ${Colors.purple};

  span {
    color: #ed3237;
    font-weight: 700;
  }

  ${media.smallDesktopMinimum`
font-size: 26px;
	`}

  ${media.mobile`
  width: 100%;
  font-size: 26px;
  line-height:26px;
  word-spacing: 0.5px;
  text-align:center;

  
  `}
`
export const HeroHeader = styled.h3`
  width: 95%;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.lightPurple};
  margin-top:15px;
  ${media.smallDesktopMinimum`
font-size: 26px;
	`}

  ${media.mobile`
  width: 100%;
  font-size: 26px;
  word-spacing: 0.5px;
  text-align:center;
  
  `}
`

export const RightDiv = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0%;
  padding: 50px;
  padding-right:100px;
  overflow: hidden;
  
  img {
    width: 100%;
    object-fit: cover;
  }

  ${media.smallDesktopMinimum`
 width: 100%;
 padding: 0px;
 margin-top: 15%;
 

  `}

  ${media.mobile`
	width: 100%;
  height:100%
	`}
`

export const ZoomImage = styled.img`
  width: 100%;
`
export const ModalContainer = styled.div`
display:flex;
alignItems:center;
`
export const ModalLeftDiv = styled.div`
h1{
font-family: Montserrat;
font-weight: 700;
font-size: 24px;
line-height: 31.47px;
letter-spacing: 0%;
color:${Colors.purple}

}

h2{
font-family: Inter;
font-weight: 550;
font-size: 16px;
line-height: 20.98px;
letter-spacing: 0%;
color:${Colors.lightGray};
}

`
export const ModalRightDiv = styled.div`
img{
margin-top:-30px;
width:100%;
height:auto;
}
${media.mobile`
  display:none;
  `}
`
