import React from 'react'
import { REQUIREMENT_ROUTE } from 'routes'
import {  NewDashImage } from 'utils/assets'
import {
  GetStartedBtn,
  HeadGroup,
  HeroBoldHeader,
  HeroContainer,
  HeroDiv,
  HeroHeader,
  HeroOverlay,
  LeftDiv,
  RightDiv,
  ZoomImage,
} from './style'
const HeroDefault = () => {
  return (
    <HeroContainer>
      {/* <BackgroundContainer /> */}
      <HeroOverlay />
      <HeroDiv>
        <LeftDiv>
          <HeadGroup>
            <HeroBoldHeader>
              Interest Free Loan For Bayelsa Students
            </HeroBoldHeader>
            <HeroHeader>
              The Bayelsa state Higher Education Students Loan Board helps
              Bayelsans presently studying in Nigerian tertiary institutions to
              scale their educational career without fear.
            </HeroHeader>
            <GetStartedBtn to={REQUIREMENT_ROUTE}> Apply </GetStartedBtn>
          </HeadGroup>
        </LeftDiv>

        <RightDiv>
          <ZoomImage src={NewDashImage} alt="student" />
        </RightDiv>
      </HeroDiv>
    </HeroContainer>
  )
}

export default HeroDefault
