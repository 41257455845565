import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React from "react"
import { AppLogo2 } from "utils/assets"

import { AuthBottomLink, AuthButton, AuthHeader } from ".."
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { EmailCreateText } from "./style"
import { sendEmail } from "services/AuthService"
import { LoginContainer, LoginDiv, LoginFormContainer } from "../Login/style"
import { HOME_ROUTE, LOGIN_ROUTE } from "routes"
import { Link } from "react-router-dom"

function CreateAccountEmail() {
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			first_name: "",
			surname: "",
			email: "",
			password: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await sendEmail(data)
			if (!response.error) {
				helpers.resetForm()
			}
		},
		validationSchema: Yup.object({
			first_name: Yup.string().max(50).required().label("First Name"),
			surname: Yup.string().max(50).required().label("Surname"),
			email: Yup.string().max(255).email().required().label("Email"),
			password: Yup.string().min(8).required("Password is required.").label("Password")
		})
	})
	return (
		<Authcontainer>
			<AuthContent>
				<Link to={HOME_ROUTE}><Img src={AppLogo2} alt="BSL" /></Link>
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Create an Account" />
								<EmailCreateText> We will send a one time password to this email adress </EmailCreateText>
								<TextInput
									astheric={true}
									label="Surname"
									name="surname"
									id="surname"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.surname}
									error={formik.errors.surname}
									visible={formik.touched.surname}
									placeholder="Surname"
								/>
								<TextInput
									astheric={true}
									label="First Name"
									name="first_name"
									id="first_name"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.first_name}
									error={formik.errors.first_name}
									visible={formik.touched.first_name}
									placeholder="First Name"
									mt="0.5rem"
								/>
								<TextInput
									astheric={true}
									label="Email Address"
									name="email"
									type="email"
									id="email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.errors.email}
									visible={formik.touched.email}
									placeholder="Email Address"
									mt="0.5rem"
								/>
								<TextInput
									astheric={true}
									label="Password"
									name="password"
									type="password"
									id="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik.errors.password}
									visible={formik.touched.password}
									placeholder="Password"
									mt="0.5rem"
								/>
								{/* <TextInput
								astheric={true}
									label="Confirm Password"
									name="confirm_password"
									type="password"
									id="confirm_password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.confirm_password}
									error={formik.errors.confirm_password}
									visible={formik.touched.confirm_password}
									placeholder="Confirm Password"
								/> */}
								<AuthButton type="submit" disabled={authStore.isLoading ? true : false} title={authStore.isLoading ? "Loading..." : "Submit"} />
								<AuthBottomLink link={LOGIN_ROUTE} />
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(CreateAccountEmail)
