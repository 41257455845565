import { Img } from 'globalStyles'
import React from 'react'
import { SocialDiv } from './style'
import { Link } from 'react-router-dom'

function Socials({
  icon,
  link = 'https://play.google.com/store/apps',
  title = 'twitter',
}) {
  return (
    <Link to={link} target="_blank">
      <button>
        <Img src={icon} height={24} width={24} alt={title} />
      </button>
    </Link>
  )
}

export default Socials
