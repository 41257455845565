import { createContext, useState,useEffect, useMemo } from "react";
import http from "services/httpService"
import { BASE_URL } from "services"
import { useNavigate, useParams } from "react-router-dom";

export const loanSteps = ["","details","surety_1","surety_2","upload_docs","loan_review","payment"]


export const UndergraduateLoanStepContext = createContext({
    step: 0,
    next:()=>{},
    prev:()=>{},
    goToStep:(newStep)=>console.log(newStep),
    loading:true,
})


export default function UndergraduateLoanStepProvider({children}){
    const [loading,setLoading] = useState(true)
    const [step,setStep] = useState(0)
    const [completedSteps,setCompletedSteps] = useState([0])
    const params = useParams()
    const navigate = useNavigate()

    const next = ()=>{
        if(step < loanSteps.length - 1){
            setStep(prev=> prev + 1)
            if(!completedSteps.includes(step + 1)){
                setCompletedSteps(prev=>[...prev,step +1])
            }
        }
    }
    const prev = ()=>{
        if(step > 0){
            setStep(prev=>prev - 1)
        }
    }

    const stepObject = useMemo(()=>({
        payment:{
            completedSteps:[0,1,2,3,4,5,6],
            currentStep:6
        },
        upload_docs:{
            completedSteps:[0,1,2,3,4],
            currentStep:4
        },
        guarantor:{
            completedSteps:[0,1,2,3,4],
            currentStep:4
        },
        surety_2:{
            completedSteps:[0,1,2,3],
            currentStep:3
        },
        surety_1:{
            completedSteps:[0,1,2],
            currentStep:2
        },
        details:{
            completedSteps:[0,1],
            currentStep:1
        },
    }),[])


    const getLoanData = useMemo(()=>{
        return async (apiURL) => {
            try {
                const res = await http.get(apiURL)
                const loan = res?.data?.data.loan
                const stepData = loan && loan[0] ? stepObject[loan[0].loan_step] : stepObject.details

                if(!loan ||  loan.length === 0) {
                    navigate("/dashboard/apply/undergraduate")
                    return;
                }

                if(stepData){
                    setCompletedSteps(stepData.completedSteps)
                    setStep(stepData.currentStep)
                    return;
                }
                setCompletedSteps([0])
                setStep(0)
            } 
            catch(e){
                console.log(e)
            }
            finally {
                setLoading(false)
            }
        }
    },[stepObject,navigate])

    const goToStep = (newStep) => {
        if(completedSteps.includes(newStep)) {
            setStep(newStep)
        }
    }

    useEffect( ()=>{
        if(params.id){
            const url = `${BASE_URL}/loans/${params?.id}`
            getLoanData(url)
        }
	},[params?.id,getLoanData])
    return (
        <UndergraduateLoanStepContext.Provider value={{step,next,prev,goToStep,loading}}>
            {children}
        </UndergraduateLoanStepContext.Provider>
    )
}