import TextInput from 'components/TextInput'
// import { Img } from 'globalStyles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect } from 'react'
import {
  InfoContainer,
  InfoContent,
  InfoContentLeft,
  // InfoContentRight,
  InfoText,
  InputDiv,
  InputWrap,
  ProceedBtn,
} from './style'
import CustomSelectMain from 'layout/SelectInput'
import { useNavigate } from 'react-router-dom'
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PROFILEROUTE, DASHBOARD_ROUTE } from 'routes'
import { empData } from './empData'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import useSWR from 'swr'
import { checkOnBoarding, resolveBank } from 'services/MiscService'
import { observer } from 'mobx-react-lite'
import { updateBankDetails } from 'services/UserService'
import authStore from 'mobx/AuthStore'
import FullScreenLoader from 'components/FullScreenLoader'
import toast from 'react-hot-toast'
import apiRoute, { onBoardStep } from 'routes/apirRoutes'

const PaymentDetailsComp = ({onboarded}) => {
  const navigate = useNavigate()
  const url = `${BASE_URL}/misc/banks`
  const fetcher = async () => {
    const res = await http.get(apiRoute.banks)
    return res?.data?.data
  }
  const fetcherTwo = async () => {
    const res = await http.get(apiRoute.whoIAm)
    return res?.data?.data
  }
  const { data: userProfile } = useSWR(apiRoute.whoIAm, fetcherTwo)
  const { data: banks } = useSWR(url, fetcher)
  let account =false;
  // console.log(userProfile, banks)

  const banksDad = banks
    ? banks?.sort(function (a, b) {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    : [{ name: 'No banks', code: '', id: 'hes' }]

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      account_name: userProfile?.bank_account?.account_name
        ? userProfile?.bank_account?.account_name
        : `${userProfile?.first_name} ${userProfile.surname}`,
      account_number: userProfile?.bank_account?.account_number
        ? userProfile?.bank_account?.account_number
        : '',
      bank_code: userProfile?.bank_account?.bank_code
        ? userProfile?.bank_account?.bank_code
        : '',
      employment_status: userProfile?.employment_status
        ? userProfile?.employment_status
        : '',
      bvn: userProfile?.bvn ? userProfile?.bvn : '',
    },

    onSubmit: async (data, helpers) => {

      const selectedBank = banks?.filter((bank) => bank.code === data.bank_code)

      const payload = { ...data }
      payload.bank_name = selectedBank[0]?.name
      const userResponse = await updateBankDetails(payload)

      console.log(userResponse,onboarded)

      if (userResponse.success && onboarded) {
        navigate(`${DASHBOARD_ROUTE}`)
      }
    },

    validationSchema: Yup.object({
      account_name: Yup.string().max(50).required().label('Account Name'),
      account_number: Yup.string()
        .min(10)
        .max(10)
        .required()
        .label('Account Number'),
      bank_code: Yup.string().max(100).required().label('Bank Name'),
      employment_status: Yup.string()
        .max(255)
        .required()
        .label('Employment Status'),
      bvn: Yup.string().min(11).max(11).required().label('BVN'),
    }),
  })

  const checkBankName = async () => {
    const resolvePayload = {
      bank_code: formik.values.bank_code,
      account_number: formik.values.account_number,
    }
    const response = await resolveBank(resolvePayload)

    if (
      response.data.data &&
      formik.values.account_number.length === 10 &&
      formik.values.account_name === ''
    ) {
      formik.setFieldValue('account_name', response?.data?.data?.account_name)
    } else {
      formik.setFieldValue('account_name', '')
    }
    
  }

  if (formik.values.account_number.length < 10 && formik.values.account_name) {
    formik.setFieldValue('account_name', '')
  }
  if (
    formik.values.account_number.length === 10 &&
    formik.values.account_name === '' && account === false
  ) {
    checkBankName()
      account= true
  } else {
      account= false
  }
  const handleOnBoarded = async () => {
    const info = await checkOnBoarding();
    if(info?.error) {
      toast.error(info.error);
    }else{
      // reroute to onboarding page
      if(onboarded){
        return;
      }
      if(!info.onBoarded && info.nextStep === onBoardStep.profile){
        return navigate(DASHBOARD_PROFILEROUTE)
      }
      if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
        return navigate(DASHBOARD_NEXTOFKINROUTE)
      }if(info.onBoardStep && info.nextStep === onBoardStep.completed){
          navigate(DASHBOARD_ROUTE);
          toast.success("Congrats! You have completed account setup");
          return;
      }
      // else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
      //   navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
      // }
    }
  }

  useEffect( ()=>{
    handleOnBoarded();
  });
  if (!banks) return <FullScreenLoader show={true} />
  return (
    <InfoContainer>
      <InfoContent>
        <InfoContentLeft onSubmit={formik.handleSubmit}>
          <InfoText>Bank Details</InfoText>
          <InputDiv>
            <InputWrap>
              <CustomSelectMain
                label="Bank Name"
                name="bank_code"
                id="bank_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_code}
                error={formik.errors.bank_code}
                visible={formik.touched.bank_code}
              >
                <option style={{ color: 'grey' }} value="">
                  Select Bank
                </option>
                {banksDad?.map((bank) => (
                  <option
                    className="active-option"
                    key={bank?.id}
                    value={bank.code}
                  >
                    {bank.name}
                  </option>
                ))}
              </CustomSelectMain>
              <TextInput
                mt="1.5rem"
                label="Account Number"
                placeholder="Account Number"
                name="account_number"
                id="account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_number}
                error={formik.errors.account_number}
                visible={formik.touched.account_number}
              />

              <TextInput
                label="Provide BVN"
                placeholder="22312256781"
                name="bvn"
                id="bvn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bvn}
                error={formik.errors.bvn}
                visible={formik.touched.bvn}
              />
            </InputWrap>

            <InputWrap>
              <TextInput
                label="Account Name."
                placeholder="John Doe"
                name="account_name"
                id="account_name"
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_name}
                error={formik.errors.account_name}
                visible={formik.touched.account_name}
              />

              <CustomSelectMain
                label="Employment Status"
                placeholder="Student"
                name="employment_status"
                id="employment_status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.employment_status}
                error={formik.errors.employment_status}
                visible={formik.touched.employment_status}
              >
                <option style={{ color: 'grey' }} value="">
                  --------
                </option>
                {empData?.map((local) => (
                  <option
                    className="active-option"
                    key={local.id}
                    value={local.value}
                  >
                    {local.label}
                  </option>
                ))}
              </CustomSelectMain>
            </InputWrap>
          </InputDiv>
          <ProceedBtn type="submit">
            {authStore.isLoading ? 'Loading...' : 'Submit'}
          </ProceedBtn>
        </InfoContentLeft>
        {/* <InfoContentRight>
          <Img src={FundsImage} alt="image" />
        </InfoContentRight> */}
      </InfoContent>
    </InfoContainer>
  )
}

export default observer(PaymentDetailsComp)
