import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const NavContainer = styled.div`
	width: 100%;
	height: 78px;
	position: sticky;
	top: 0;
	padding: 0px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${({ bgColor }) => (bgColor ? bgColor : "white")};
	z-index: 1000;
	border-bottom: 1px solid #ccc;

	.home-link {
		img {
			width: 180px;
		}
	}

	${media.smallDesktopMinimum`
  width: 100%;
  `}
	${media.mobile`
  width: 100%;
  padding: 0px 20px;
  `}
`
export const Ul = styled.ul`
	display: flex;
	list-style-type: none;
	text-decoration: none;
	li {
		margin: 0px 3px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: ${Colors.black};
		text-decoration: none;
	}
	a {
		text-decoration: none;
		color: ${Colors.black};
		padding: 10px;
		&.is-active {
			color: ${Colors.primary};
		}
		&:hover {
			opacity: 0.8;
		}
	}

	${media.mobile`
  display:none;
  `}
`
export const Ulist = styled.ul`
	display: flex;
	list-style-type: none;
	text-decoration: none;
	li {
		margin: 0px 3px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: 0.0075em;
		color: ${({ fontColor }) => (fontColor ? fontColor : `${Colors.white}`)};
		text-decoration: none;
	}
	a {
		text-decoration: none;
		color: ${({ fontColor }) => (fontColor ? fontColor : `${Colors.white}`)};
		padding: 10px;
		&.is-active {
			color: ${Colors.primary};
		}
		&:hover {
			opacity: 0.8;
		}
	}
	${media.mobile`
  display:none;
  `}
`
export const NavActionButton = styled.button`
	width: 100px;
	height: 30px;
	outline: none;
	border: none;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 12px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	color: ${Colors.primary};
	border-radius: 10px;
	cursor: pointer;
	${media.mobile`
	display: none;
	`}
`
export const CreateAccountButton = styled.button`
 width: 142px;
  height: 42px;
  gap: 10px;
  border-radius: 7px;
  padding: 11px 11px;
  margin-left:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: ${Colors.purple};
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  line-height: 12px;
  font-weight:700;
font-family: 'Montserrat', sans-serif;

@media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    font-size: 12px;
  }

		${media.mobile`
	display:none
	`}
`
export const CreateButton = styled.button`
	height: 30px;
	outline: none;
	border: none;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	margin-left: 15px;
	font-size: 12px;
	line-height: 12px;
	padding: 0px 20px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	background: ${({ createBtnColor }) => (createBtnColor ? createBtnColor : ` ${Colors.white}`)};
	color: ${Colors.black};
	border-radius: 10px;
	cursor: pointer;
	${media.mobile`
	display: none;
	`}
`
export const NavBarEndDiv = styled.div`
	display: flex;
	align-items: center;
	${media.mobile`
	margin-left:5px;
	`}
`
export const LangDiv = styled.div`
	display: flex;
	align-items: center;
	margin-left: 25px;
	img {
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}
	p {
		margin-left: 3px;
	}
	${media.mobile`
	display: none;
	`}
`
export const HamBurgerBtn = styled.button`
	display: none;
	width: 50px;
	height: 30px;
	outline: none;
	border: none;
	background: transparent;
	margin-left: 8px;
	padding: 0;
	img {
		width: 100%;
		height: 100%;
	}
	${media.tablet`
	display:none;
	`}
	${media.mobile`
	display:flex;
	position: relative;
	`}
`

export const ButtonStyle = styled.button`
	background: ${props => (props.variant === "primary" ? " linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%)" : "#FFFFFF")};
	color: ${props => (props.variant === "primary" ? "#FFFFFF" : "000000")};
	width: ${props => (props.variant === "primary" ? "100px" : "140px")};
	height: 35px;
	border-radius: 10px;
	border: none;
	outline: none;
	cursor: pointer;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? '0' : '-100%')};
  width: 100%;
  height: 100%;
  background: ${({ bgColor }) => bgColor};
  opacity: 1; /* Adjust as needed */
  transition: background 0.3s ease, left 0.3s ease;
  z-index: 1000;
`;

