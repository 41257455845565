import MainLayout from "layout/MainLayout";
import { Link } from "react-router-dom";
import {requirementBanner} from "utils/assets"
import { SIGNUP_ROUTE } from "routes";

const requirements = [
    "Birth Certificate/Age Declaration: Proof of age.",
    "Local Government Area Identification: Verification of Bayelsa indigene status.",
    " Applicant’s Passport: Recent passport-sized photograph.",
    "Applicant’s School ID Card or Any Other ID Card: Valid identification.",
    "Admission Letter: Proof of admission into an educational institution.",
    "Original Letter of Identification: From the Registrar, Dean of Student Affairs, or Faculty Dean, duly stamped and signed.",
    "Guarantor’s Valid Identification: National ID Card."
    , "Previous Certificate: Required for postgraduate applicants.",
    "Evidence of Past School Fees Payment: For students reapplying for the loan."
]

export default function LoanRequirementPage() {
    return (
        <MainLayout>
            <div className="bg-[#F3F3F6] pt-[31px] pb-[100px] sm:py-[85px] min-h-screen">
                <section className="min-h-screen lg:flex gap-x-4 items-center max-w-[1196px] p-4 mx-auto">
                    <div className="flex-1">
                        <p className="text-xl sm:text-2xl font-bold font-poppins text-[#140D47]">Applicants must meet the following requirements to be eligible for the loan:</p>
                        <div className="flex flex-col gap-y-4 mt-[22px] mb-[30px]">
                            {requirements.map((requirement,index) => (
                                <p className="text-[#3C3C3C] text-base font-poppins font-bold">{index+1}. {requirement}</p>
                            ))}
                        </div>
                      <div className="text-center sm:text-left">
                        <Link to={SIGNUP_ROUTE}>
                                <button className="min-w-[246px] h-[54px] font-poppins font-bold rounded-md text-white bg-[#140D47]">Apply Now</button>
                            </Link>
                      </div>
                    </div>
                    <div className="flex-1">
                            <img src={requirementBanner} alt="Loan Requirement"  className="hidden md:block w-full"/>
                    </div>
                </section>
            </div>
        </MainLayout>
    )
}