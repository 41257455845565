import TextInput from "components/TextInput"
import { Div } from "globalStyles"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useContext, useEffect, useState } from "react"
import {
	InfoText,
} from "./style"
import CustomSelectMain from "layout/SelectInput"
// import bankData from "./bankData"
import { empData } from "./empData"
import { BASE_URL } from "services"
import http from "services/httpService"
// import useSWR from "swr"
// import { resolveBank } from "services/MiscService"
import { observer } from "mobx-react-lite"
import { addLoanDetailsUnder} from "services/LoanService"
import loanStore from "mobx/LoanStore"
import FullScreenLoader, { FormLoader } from "components/FullScreenLoader"
import "components/CheckBox/radio.css"
import { useParams } from "react-router-dom"
import { UndergraduateLoanStepContext } from "context/LoanStepContext"
import useForm from "./hooks/useForm"
import { Spin } from "antd"
// import CustomCheckBox from "components/CheckBox"


const LoanComp = (props) => {
	const { loanType="undergraduate", mutate,} = props
	let {id} = props
	let segment;
	const fullUrl = window.location.href;
	segment = fullUrl.split('/').pop();

	const {formValues,handleInput,loadingForm} = useForm(segment)
	const [loading, setLoading] = useState(false)
	const {next,prev} = useContext(UndergraduateLoanStepContext)
	const params = useParams()

	const [banks,setBanks] = useState([])
	const [bankDetail,setBankDetail] = useState({
		account_name:"",
		account_number:"",
		bank_code:"",
	})

	const sortBanks = (bankData)=>{
		if(!bankData){
			return [{ name: "No banks", code: "", id: "hes" }]
		}
		return bankData.sort(function (a, b) {
			const nameA = a.name.toUpperCase()
			const nameB = b.name.toUpperCase()
			if (nameA < nameB) return -1 ;
			if (nameA > nameB) return 1 ;
			return 0
		})

	}

	useEffect(()=>{
		const url = `${BASE_URL}/misc/banks`
		const url2 = `${BASE_URL}/users/who_i_am`

		const fetchData = async (apiURL) => {
			const res = await http.get(apiURL)
			return res?.data?.data
		}
		const getUserData = async()=>{
			const banks = await fetchData(url)
			const userData = await fetchData(url2)

			const {
				account_name,
				account_number,
				bank_code,
			} = userData.bank_account

			setBankDetail({
				account_name,
				account_number,
				bank_code
			})
			setBanks(banks)
		}
		getUserData()
	},[segment])



	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...formValues,
			...bankDetail,
		},
		onSubmit: async (data, helpers) => {
			if (loanType === "undergraduate") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)
				const payload = { ...data }
				console.log(id)
				payload.id =  params.id
				payload.bank_name = selectedBank[0]?.name

				setLoading(true)
				const underResponse = await addLoanDetailsUnder(payload)
				setLoading(false)
				console.log(underResponse)
				if(underResponse.error){
					console.log(underResponse.error)
				}else{
					mutate()
					next()
				}
			}
			if (loanType === "postgraduate") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)

				const payload = { ...data }
				payload.id = id
				payload.bank_name = selectedBank[0]?.name

				setLoading(true)
				const underResponse = await addLoanDetailsUnder(payload)
				setLoading(false)
				if (!underResponse.error) {
					mutate()
				}
			}
			if (loanType === "professional") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)

				const payload = { ...data }
				payload.id = id
				payload.bank_name = selectedBank[0]?.name

				setLoading(true)
				const underResponse = await addLoanDetailsUnder(payload)
				setLoading(false)
				if (!underResponse.error) {
					mutate()
				}
			}
		},

		validationSchema: Yup.object({
			account_name: Yup.string().max(50).required().label("Account Name"),
			account_number: Yup.string().min(10).max(10).required().label("Account Number"),
			bank_code: Yup.string().max(100).required().label("Bank Name"),
			amount: Yup.string().required().label("How Much do you need"),
			purpose: Yup.string().max(150).required().label("Purpose of loan"),
			employment_status: Yup.string().max(255).required().label("Employment Status"),
			loan_tenure: Yup.number().required().label("Loan Tenure")
		})
	})

   const commaNumber = (number)=>number.toLocaleString()
	if (!banks || loadingForm) return <FullScreenLoader show={true} />

	return (
		<>
		{loadingForm && <FormLoader/>}
		<form onSubmit={formik.handleSubmit}>
			<div >
				<div >
					<header className="py-8 text-center">
						<InfoText>Loan Details</InfoText>
					</header>
					<div className="mx-auto max-w-xl bg-white rounded-lg p-4 py-8 mb-10">
						<div>
							<TextInput
								astheric={true}
								label="How Much do you need"
								type="number"
								placeholder="200,000"
								name="amount"
								id="amount"
								onChange={handleInput}
								onBlur={formik.handleBlur}
								value={formValues.amount}
								error={formik.errors.amount}
								visible={formik.touched.amount}
							/>
							<TextInput
								astheric={true}
								label="Purpose of loan"
								placeholder="School Fees"
								name="purpose"
								id="purpose"
								onChange={handleInput}
								onBlur={formik.handleBlur}
								value={formValues.purpose}
								error={formik.errors.purpose}
								visible={formik.touched.purpose}
							/>
							<CustomSelectMain
								astheric={true}
								label="Bank Name"
								name="bank_code"
								id="bank_code"
								disabled={true}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.bank_code}
								error={formik.errors.bank_code}
								visible={formik.touched.bank_code}
							>
								<option style={{ color: "grey" }} value="">
									Select Bank
								</option>
								{sortBanks(banks).map(bank => (
									<option className="active-option" key={bank?.id} value={bank.code}>
										{bank.name}
									</option>
								))}
							</CustomSelectMain>
							<TextInput
								astheric={true}
								label="Account Number"
								placeholder="Account Number"
								name="account_number"
								id="account_number"
								disabled={true}
								// onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.account_number}
								error={formik.errors.account_number}
								visible={formik.touched.account_number}
							/>
							<TextInput
								astheric={true}
								label="Account Name."
								placeholder="John Doe"
								name="account_name"
								id="account_name"
								disabled
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.account_name}
								error={formik.errors.account_name}
								visible={formik.touched.account_name}
							/>
							<CustomSelectMain
								label="Employment Status"
								placeholder="Student"
								name="employment_status"
								id="employment_status"
								disabled
								onBlur={formik.handleBlur}
								value={formValues.employment_status}
								error={formik.errors.employment_status}
								visible={formik.touched.employment_status}
							>
								<option style={{ color: "grey" }} value="">
									{/* -------- */}
								</option>
								{empData?.map(local => (
									<option className="active-option" key={local.id} value={local.value}>
										{local.label}
									</option>
								))}
							</CustomSelectMain>
							<div className="my-4">
								<label style={{ marginBottom: "5px" }}>
									Loan Tenure<span style={{ color: "red" }}>*</span>
								</label>
								<div className="wrapper">
									<input type="radio" name="loan_tenure" checked={formValues.loan_tenure === "12"} value="12" onChange={handleInput} id="option-1" />
									<input type="radio" name="loan_tenure" checked={formValues.loan_tenure === "24"} value="24" onChange={handleInput} id="option-2" />
									<input type="radio" name="loan_tenure" checked={formValues.loan_tenure === "36"} value="36" onChange={handleInput} id="option-3" />
									<label htmlFor="option-1" class="option option-1">
										<div className="dot"></div>
										<span>12months</span>
									</label>
									<label htmlFor="option-2" className="option option-2">
										<div className="dot"></div>
										<span>24months</span>
									</label>
									<label htmlFor="option-3" className="option option-3">
										<div className="dot"></div>
										<span>36months</span>
									</label>
								</div>
								{formik.touched.loan_tenure && formik.errors.loan_tenure && <span style={{ color: "red" }}>{formik.errors.loan_tenure}</span>}

								<Div mt="1.5rem">
									{formik.values.amount && formik.values.loan_tenure && !formik.errors.loan_tenure && (
										<p className="font-poppins text-lg font-semibold text-secondary">
											Monthly Repayment Amount: ₦{commaNumber(parseFloat(formValues.amount)/parseFloat(formValues.loan_tenure))}
										</p>
									)}
									{formik.values.amount && formik.values.loan_tenure && !formik.errors.loan_tenure && (
										<p className="mt-1 text-sm font-poppins">You start repaying six(6) months after disbursement</p>
									)}
								</Div>
							</div>
						</div>

					</div>

					{/* <ProceedBtn type="button" onClick={prev}> Back </ProceedBtn>
					<ProceedBtn type="submit" disabled={loanStore.isLoading}>
						{loanStore.isLoading ? "Loading..." : "Proceed"}
					</ProceedBtn> */}
				</div>
			</div>
			<footer className="flex py-2 gap-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
				<button onClick={prev} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button" disabled={loanStore.isLoading}>
					<span>Back</span>
				</button>
				<button className="bg-secondary-700 text-white rounded-full py-2 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="submit" disabled={loading}>
					{loading
					?<Spin/>:
					<span>Proceed</span>}
				</button>
			</footer>
		</form>
		</>
	)
}

export default observer(LoanComp)
