import {useEffect, useState } from "react"
import { BASE_URL } from "services"
import http from "services/httpService"
import { getCurrentLoanStage } from "services/LoanService"
import "components/CheckBox/radio.css"


export default function useForm(segment){
    const [loadingForm,setLoadingForm] = useState(false)
    const [loanDetail,setLoanDetail] = useState(null)
	const [formValues,setFormValues] = useState({
			amount: "",
			purpose:"",
			loan_tenure:12,
			employment_status: "employed"
	})

    const handleInput = (e)=>{
        setFormValues(prev=>({
            ...prev,
            [e.target.name]:e.target.value
        }))
    }


    useEffect(()=>{
        const url = `${BASE_URL}/users/who_i_am`
        const fetchData = async (apiURL) => {
			const res = await http.get(apiURL)
			return res?.data?.data
		}
        const getUserData = async()=>{
            setLoadingForm(true)
            const res = await getCurrentLoanStage({ search: "loan_details", loanId: segment });
            const userData = await fetchData(url)
            setLoadingForm(false)

			const loanDetails = res?.data?.data;
			const {purpose,amount,loan_tenure} = loanDetails
            const {employment_status} = userData

			setLoanDetail(loanDetails)

			setFormValues(prev=>({
				...prev,
				purpose:purpose || prev.purpose,
				amount:amount|| prev.amount,
				loan_tenure:loan_tenure?`${loan_tenure}`:prev.loan_tenure,
                employment_status:employment_status || prev.employment_status
			}))
		}
		getUserData()
    },[segment])

   return{
    handleInput,
    loadingForm,
    formValues,
    loanDetail
   }
}