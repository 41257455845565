import { Img } from "globalStyles"
import React from "react"
import { Link } from "react-router-dom"
import { ABOUT_ROUTE, BLOG_ROUTE, FAQ_ROUTE, GET_LOAN_ROUTE, HOME_ROUTE } from "routes"
import { AppLogo2, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon } from "utils/assets"
import FooterItem from "./FooterItem"
import Socials from "./Socials"

function Footer() {
	return (
		<footer className="bg-primary text-white py-[87px]">
			<section className="flex flex-col lg:flex-row gap-x-12 items-center max-w-[1196px] px-4 mx-auto">
				<div className="flex-[2]">
					<div>
						<Link to={HOME_ROUTE}><Img className="max-w-[200px]" src={AppLogo2} alt="BSL" /></Link>
					</div>
					<p className="desc-text font-medium font-montserrat mt-4 mb-8 text-sm">Welcome to BYSHELB a Bayelsa State Government initiative that provides interest free loans to its indigines studying in Nigerian public higher institutions.</p>
					<p className="copyright-text font-montserrat text-sm">© Copyright {new Date().getFullYear()} | Byshelb</p>
				</div>
				<div className="flex-[3] w-full bg-[#0D092F] mt-[38px] lg:mt-0 py-[30px] px-12 rounded-[15px]">
					<div>
						<ul className="flex flex-col md:flex-row md:items-center font-bold mb-9 gap-12">
							<li className="bottom-link-start">
								<Link className="links-footer" to={HOME_ROUTE}>
									Home
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={GET_LOAN_ROUTE}>
									Get A Loan
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={ABOUT_ROUTE}>
									About Us
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={FAQ_ROUTE}>
									FAQs
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={BLOG_ROUTE}>
									Blog
								</Link>
							</li>
						</ul>
						<FooterItem icon={LocationIcon} text="Okaka Estate, off Sani Abacha Express Way, Yenagoa" />

						<div className="mt-10 flex items-center justify-center md:justify-start gap-x-12">
							<Socials icon={TwitterIcon} title="twitter" link="http://facebook.com" />
							<Socials icon={FacebookIcon} title="facebook" link="http://facebook.com" />
							<Socials icon={InstagramIcon} title="Instagram" link="http://facebook.com" />
							<Socials icon={LinkedinIcon} title="linkedin" link="http://facebook.com" />
						</div>
					</div>
				</div>
			</section>
		</footer>
	)
}

export default Footer
