import AuthSideNavDesk from "components/AuthNav/SideBar/SideBarMobile"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LOGIN_ROUTE } from "routes"
import { setJwt } from "services/httpService"

const AuthLayout = ({ children }) => {
	const navigate = useNavigate()
	useEffect(() => {
		const status = authStore.loggedInStatus()
		const userStatus = sessionStorage.getItem("token")
		userStatus ? setJwt(userStatus) : setJwt("")
		if (!status) {
			navigate(`${LOGIN_ROUTE}`)
		}
	}, [navigate])
	return (
		<div className="bg-[#E7E7ED]">
			{/* <MainContainer> */}
			<div>
				<AuthSideNavDesk />
				<div className="lg:ml-[250px]">
					
					{" "}
					{children}
					{/* <Outlet /> */}
				</div>
			</div>
			{/* </MainContainer> */}
		</div>
	)
}

export default observer(AuthLayout)
