import { useEffect, useState } from "react";
import { BASE_URL } from "services";
import http from "services/httpService";

export default function useLoanDetail(loanId){
    const [loading,setLoading] = useState(true)
    const [loan,setLoan] = useState(null)
    let segment;
    const fullUrl = window.location.href;
    segment = fullUrl.split('/').pop();
    useEffect(()=>{
        const url = `${BASE_URL}/loans/${loanId || segment}`
        const getData = async () => {
        const res = await http.get(url)
        const loan = res?.data?.data.loan
            if(loan){
                setLoan(loan[0])
            }
            setLoading(false)

        }
        getData()
    },[segment,loanId])

    return {
        loading,
        loan
    }
}