import styled from 'styled-components'
import Colors from 'utils/colors'
import media from 'utils/media'

export const BlogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* ${media.mobile`
  padding: 0px 30px;
  background-color: red;
  
  `} */
`

export const NewsHeader = styled.div`
  padding: 30px 0px;
  h3 {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: ${Colors.purple};

    span {
      font-weight: 700;
       color: ${Colors.purple};
    }
  }

  ${media.mobile`
  h3{
	font-size: 22px;
  span {
      font-weight: 520;
    }
  }
  `}
`

export const PostContainer = styled.div`
  width: 100%;
  padding: 0px 60px;
  display: flex;
  gap:10px;
  justify-content: center;
  .keen-slider{
    img{
      width: 100%;
    }
  }
`
export const PostCard = styled.div`
  width: 100%;
  height: 273px;
  position: relative;
  border-radius: 8px;
margin:10px;
  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
border-radius: 8px;

  }
  ${media.mobile`
  width: 100%;
  height: 350px;
  
  `}
`
export const PostCardOver = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top:10px;
  .blog-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
  }

  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 107.5%;
    /* identical to box height, or 14px */
    letter-spacing: -0.04em;

    color: ${Colors.black};
  }
`
