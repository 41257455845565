const Colors = {
	primary: "#391A60",
	primaryLight: "rgba(20, 13, 71, 1)",
	secondary: "#EEBC1D",
	black: "#000000",
	white: "#FFFFFF",
	muted: "#D9D9D9",
	light: "#A7A7A7",
	gradient: "linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%)",
	whiteGradient: "rgba(0,0,0, 0.5)",
	pendingBg: "#FEECD7",
	pendingColor: "#F6A74B",
	grey:{
		50:"#FAFAFA",
		100:"#F5F5F5",
		200:"#EEEEEE",
		300:"#E0E0E0",
		400:"#BDBDBD",
		500:"#9E9E9E",
		600:"#757575",
		700:"#616161",
		800:"#424242",
		900:"#212121",
	},
	approvedBg: "#D2FED2",
	approvedColor: "#205E20",
	rejectedBg: "#FD66D6",
	rejectedColor: "#FF3B3B",
	disbursedBg: "#D2FED2",
	disbursedColor: "#205E20",
	dueBg: "#FF9C00",
	dueColor: "#000000",
	purple: '#140D47',
	darkPurple: '#0D092F',
	lightPurple: '#3C3C3C',
	lightGray:'#3C3C3C'

}
export default Colors

// W1)qLDTEYLA.T47f
