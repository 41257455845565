import { message, Modal, Skeleton, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getUserNotifications, readNotification, removeNotification } from "services/UserService";
import { DASHBOARD_NOTIFICATION_ROUTE } from "routes";

export function useNotifications(isPage=false){
    const [loading,setLoading] = useState(false);
    const [loadingReadAll,setLoadingReadAll] = useState(false);
    const [notifications, setNotifications] = useState([]);

   const getNotifications =  useMemo(()=>async function(){
        setLoading(true);
        const [data,err]= await getUserNotifications()
        setLoading(false);
        if(err){
            message.error(err.message)
            return;
        }
        const sortedNotifications =  data.data.sort((n1,n2)=>{
            const d1 = new Date(n1.createdAt);
            const d2 = new Date(n2.createdAt);
            return d2 - d1;
        })
        if(isPage){
            setNotifications(sortedNotifications);
            return;
        }
        setNotifications(sortedNotifications.filter(n=>!n.read));
    },[isPage])

    async function readAllNotifications(){

        const unreadNotification = notifications.filter(n => !n.read)
        const unreadNotificationIds = unreadNotification.map(n => n._id)

        setLoadingReadAll(true);
        const [,err]= await readAllNotifications(unreadNotificationIds)
        setLoadingReadAll(false);
        if(err){
            message.error(err.message)
            return;
        }
        getNotifications()
    }


    useEffect(()=>{
        getNotifications()
    },[getNotifications])

    return{
        notifications,
        loading,
        loadingReadAll,
        readAllNotifications,
        refetch:getNotifications
    }
}

function Loader(){
    const loaderCounter = new Array(3).fill("").map((item,i)=>`notification-loader-${i}-${item}`)
    return (
        <ul className="h-[220px] overflow-y-auto">
            {loaderCounter.map(loaderCount=>(
                <li key={loaderCount} className="p-2 m-2 mx-4 border-l-4"><Skeleton.Button active block style={{height:"70px"}}/></li>
            ))}
        </ul>
    )
}

function Empty(){
    return (
        <div className="text-center h-[140px] flex items-center justify-center">
            <div>
                <p className="font-poppins text-gray-700 text-lg font-semibold">You don't have any Notification</p>
            </div>
        </div>
    )
}

function Alert(props){
    const [confirmationOpen,setConfirmationOpen] = useState(false)
    const{message,subject,message_type,loan,refetch,_id,read} = props
    const [reading,setReading] = useState(false)
    const [removing,setRemoving] = useState(false)


    // const [loadingDelete,setLoadingDelete] = useState(false)
  
    async function handleReadNotification(){
        setReading(true);
        const [,err]= await readNotification(_id)
        setReading(false);
    
        if(err){
            message.error(err.message)
            return;
        }
        refetch()
    } 
    async function handleRemoveNotification(){
        setRemoving(true);
        const [,err]= await removeNotification(_id)
        setRemoving(false);

        if(err){
            message.error(err.message)
            return;
        }
        refetch()
    }


    const alertMessage = message.length > 100?`${message.slice(0,100)}...`:message;
    const isDraft = (message_type === "loan draft")
    return (
        <>
        <Modal open={confirmationOpen} footer={null} centered>
            <div className="p-4 font-poppins">
                <header className="text-center my-3">
                    <p className="text-2xl font-poppins font-bold">Confirm Remove Notification</p>
                    <p className="text-base font-poppins">Are you sure you want to remove this notification, this cannot be reversed</p>
                </header>
                <footer className="flex gap-x-4 items-center justify-center">
                    <button onClick={()=>setConfirmationOpen(false)} className="px-4 py-2 text-sm bg-red-400 text-white rounded-md">Cancel</button>
                    <button disabled={removing} onClick={handleRemoveNotification} className="px-4 py-2 text-sm bg-primary-800 text-white rounded-md">
                        {removing
                         ?<span><Spin/></span>
                         :<span>Continue</span>
                         }
                    </button>
                </footer>

            </div>
        </Modal>
        <li className="p-2 pl-3 relative m-2 mx-4 border-l-4 bg-[#E7E7ED] border-solid border-primary-700">
                <button onClick={()=>setConfirmationOpen(true)} className="bg-white absolute right-2 top-2 flex items-center justify-center h-6 w-6 rounded-full">
                    <i className="bi bi-x"></i>
                </button>
            <p className="font-poppins text-primary text-base font-semibold capitalize">{subject}</p>
            <p className="font-poppins text-primary text-xs">{alertMessage}</p>
            <div className="flex justify-end gap-x-4 mt-3">
                {!read && (
                    <button onClick={handleReadNotification} disabled={reading} className="text-xs flex items-center text-primary-800 p-3 py-1 border border-solid border-primary-300 bg-white rounded-lg font-semibold">
                        <span className="pr-2">Mark as read</span>
                        <i className="bi bi-check2-circle text-base"></i>
                    </button>
                )}
                {loan && (
                    isDraft && loan ?
                    (<Link to={`/dashboard/apply/${loan.loan_type}/${loan.id}`}>
                        <button className="text-xs flex items-center text-primary-800 border border-solid border-primary-300 p-3 py-1 rounded-lg bg-white font-semibold">
                            <span className="pr-2">See Loan</span>
                            <i className="bi bi-arrow-right text-base"></i>
                        </button>
                    </Link>)
                    :(<Link to={`/dashboard/apply/details/${loan.id}`}>
                        <button className="text-xs flex items-center text-primary-800 p-3 py-1 border border-solid border-primary-300 bg-white rounded-lg font-semibold">
                            <span className="pr-2">See Loan</span>
                            <i className="bi bi-arrow-right text-base"></i>
                        </button>
                    </Link>)
                )}
            </div>
        </li>
        </>
    )
}



export default function Notification({isPage=false}){
    const {notifications,loading,refetch} = useNotifications(isPage);

    return(
        <div className="bg-white pb-10 border border-solid border-gray-300 rounded-lg">
            <header className="flex justify-between sticky border-solid top-0 bg-white py-2 px-2 border-b border-secondary-200">
                <p className="flex gap-2 text-sm sm:text-lg text-primary items-center">
                    <span><i className="bi bi-bell"></i></span>
                    <span className="font-medium">Notifications ({notifications.length})</span>
                </p>
                {
                    !isPage && (
                        <Link to={DASHBOARD_NOTIFICATION_ROUTE}>
                        <button className="bg-primary-100 border border-solid border-primary-500 text-primary-600 px-4 py-2 text-xs sm:text-sm rounded-full">See All</button>
                    </Link>
                    )
                }
            </header>
            {loading
                ?<Loader/>
                :notifications.length > 0
                ?(<ul className={` ${isPage?"max-h-[70vh] min-h-[220px]":"h-[220px]"} overflow-auto`}>
                    {notifications.map(notification=>(<Alert refetch={refetch} {...notification}/>))}
                </ul>)
                :<Empty/>
            }
        </div>
    )
}