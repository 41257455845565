import dashboardIcon from "assets/icons/dashboard.svg"
import dashboardActive from "assets/icons/dashboardActive.svg"

import userIcon from "assets/icons/userIcon.svg"
import userActive from "assets/icons/userIconActive.svg"

import walletIcon from "assets/icons/wallet.svg"
import walletActive from "assets/icons/walletActive.svg"

import applyIcon from "assets/icons/applyIcon.svg"
import applytActive from "assets/icons/applyActive.svg"

import settingsIcon from "assets/icons/settings.svg"
import settingsActive from "assets/icons/settingsActive.svg"

import { DASHBOARD_APPLY_ROUTE, DASHBOARD_NOTIFICATION_ROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE, DASHBOARD_ROUTE, DASHBOARD_SETTINGS_ROUTE } from "routes"

export const menuData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: DASHBOARD_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},
	{
		icon: walletActive,
		activeIcon: walletIcon,
		text: "Payment Details",
		isActive: false,
		link: DASHBOARD_PAYMENT_DETAILS_ROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "My Applications",
		isActive: false,
		link: DASHBOARD_APPLY_ROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Notifications",
		isActive: false,
		link: DASHBOARD_NOTIFICATION_ROUTE
	},
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]
