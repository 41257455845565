import toast from "react-hot-toast"
import authStore from "mobx/AuthStore"

import { BASE_URL } from "services"
import http from "./httpService"
import apiRoute from "routes/apirRoutes"

export async function updatePersonalDetails(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/auth/create_account`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function updateProfile(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/auth/create_account`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function updateNextOfKinUser(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(apiRoute.updateNextOfKin, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function updateBankDetails(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(apiRoute.updateBankDetails, payload)
		toast.success(data.msg)
			authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.error)
		} else {
			authStore.loading()
			toast.error(err?.error || err)
		}
	}
}
export async function getUserNotifications() {
	try {
		const { data } = await http.get(apiRoute.notifications)
		return [data,null]
	} catch (err) {
		return [null,err]
	}
}
export async function removeNotification(id) {
	try {
		const { data } = await http.delete(`${apiRoute.notifications}/${id}`)
		return [data,null]
	} catch (err) {
		return [null,err]
	}
}
export async function readNotification(id) {
	try {
		const { data } = await http.patch(`${apiRoute.notifications}/${id}`)
		return [data,null]
	} catch (err) {
		return [null,err]
	}
}
export async function readAllNotification(ids) {
	try {
		const { data } = await http.patch(`${apiRoute.notifications}`,{ids})
		return [data,null]
	} catch (err) {
		return [null,err]
	}
}
